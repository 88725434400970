// src/i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './language/en.json';
import ja from './language/ja.json';



// Vueでvue-i18nを使用するためにプラグインを登録
Vue.use(VueI18n);

// 翻訳メッセージを定義
const messages = {
    en, // en.jsonの内容を読み込む
    ja  // ja.jsonの内容を読み込む
  };

const savedLanguage = localStorage.getItem('language') || 'en';

// i18nインスタンスを作成
const i18n = new VueI18n({
  locale: savedLanguage, // デフォルトの言語を英語に設定
  fallbackLocale: 'en', // フォールバックとして英語を指定
  messages, // 翻訳メッセージを渡す
});

export default i18n;
