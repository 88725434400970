import Vue from 'vue'; // Importing Vue library
import App from './App.vue'; // Importing the root component
import router from './router'; // Importing the router configuration
import ElementUI from 'element-ui'; // Importing Element UI library
import 'element-ui/lib/theme-chalk/index.css'; // Importing Element UI CSS styles

import '@/assets/css/global.css'; // Importing global CSS styles
import '@/assets/css/iconfont/iconfont.css'; // Importing custom icon font CSS styles
import '@/assets/css/theme/index.css'; // Importing theme CSS styles
import 'highlight.js/styles/monokai-sublime.css'; // Importing code highlighting CSS styles

import request from "@/utils/request"; // Importing custom request utility

import i18n from './i18n';

import axios from 'axios'; // Importing Axios for HTTP requests

Vue.prototype.$axios = axios; // Setting Axios as a prototype method in Vue instances

Vue.config.productionTip = false; // Disabling production tip in Vue

import locale from 'element-ui/lib/locale/lang/ja'; // Importing Japanese locale for Element UI
Vue.use(ElementUI, { locale }); // Using Element UI with Japanese locale

Vue.prototype.$request = request; // Setting custom request utility as a prototype method in Vue instances
Vue.prototype.$baseUrl="api";// Setting base URL for deploy
// Vue.prototype.$baseUrl = "https://localhost:8888"; // Setting base URL for develop

new Vue({
  i18n, 
  router,
  render: h => h(App)
}).$mount('#app'); // Mounting the root Vue instance to the '#app' element in the DOM
