import axios from 'axios'
import router from "@/router";

// Create a new axios instance
const request = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 30000
})

// Request interceptor
// Can perform some processing on the request before sending it
// Such as adding a token uniformly, encrypting request parameters
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let user = JSON.parse(localStorage.getItem("honey-user") || '{}')
    config.headers['token'] = user.token  // Set the request header

    return config
}, error => {
    console.error('request error: ' + error) // for debug
    return Promise.reject(error)
});

// Response interceptor
// Can handle the result uniformly after receiving the interface response
request.interceptors.response.use(
    response => {
        let res = response.data;

        // Handle server-side returned string data
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        if (res.code === '401') {
            router.push('/login')
        }
        return res;
    },
    error => {
        console.error('response error: ' + error) // for debug
        return Promise.reject(error)
    }
)

export default request
