import Vue from 'vue'
import VueRouter from 'vue-router'

// Fix the issue of frequent clicking on navigation bar or bottom navigation tabBar causing errors in vue-router version 3.0 and above.
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/home',  // Redirect to the home page
    children: [
      { path: '403', name: 'Auth', meta: { name: '権限なし',nameEnglish:'No Auth' }, component: () => import('../views/manager/Auth') },
      { path: 'home', name: 'Home', meta: { name: 'トップページ' ,nameEnglish: 'Top Page'}, component: () => import('../views/manager/Home') },
      { path: 'storyboard', name: 'Storyboard', meta: { name: '絵コンテページ' ,nameEnglish: 'Storyboard Page'}, component: () => import('../views/manager/Storyboard') },
      { path: 'user', name: 'User', meta: { name: 'ユーザ管理' ,nameEnglish:'User Management'}, component: () => import('../views/manager/User') },
      { path: 'person', name: 'Person', meta: { name: 'ユーザページ' }, component: () => import('../views/manager/Person') },
      { path: 'password', name: 'Password', meta: { name: 'パスワードの変更' }, component: () => import('../views/manager/Password') },
      { path: 'work-page', name: 'work-page', meta: { name: '作業ページ' ,nameEnglish:'Work Page'}, component: () => import('../views/manager/WorkPage') },
      { path: 'variation-page', name: 'variation-page', meta: { name: 'バージョンページ' ,nameEnglish:'Version Page'}, component: () => import('../views/manager/VariationPage') },
      { path: 'video-page', name: 'video-page', meta: { name: 'ビデオページ' ,nameEnglish: 'Video Page'}, component: () => import('../views/manager/VideoPage') },
      { path: 'production-manager-page', name: 'production-manager-page', meta: { name: '制作進行ページ' ,nameEnglish: 'Production Manage Page'}, component: () => import('../views/manager/ProductionManagerPage') },
      { path: 'watch-documents-page', name: 'watch-documents-page', meta: { name: '資料配布ページ', nameEnglish: 'Distribution Page'}, component: () => import('../views/manager/WatchDocumentsPage') },
      { path: 'setting-page', name: 'setting-page', meta: { name: '設定資料ページ' ,nameEnglish:'Setting Page'}, component: () => import('../views/manager/SettingPage') },
    ]
  },
  { path: '/login', name: 'Login', meta: { name: 'ログイン' }, component: () => import('../views/Login.vue') },
  { path: '/register', name: 'Register', meta: { name: '登録' }, component: () => import('../views/Register.vue') },
  { path: '*', name: '404', meta: { name: '无法访问' }, component: () => import('../views/404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 'to' represents the destination route information
  // 'from' represents the current route information
  // 'next' is the function to help us navigate to the route
  let adminPaths = ['/user']
  let user = JSON.parse(localStorage.getItem('honey-user') || '{}')

  if (user.role !== '管理員' && adminPaths.includes(to.path)) {
    // If the currently logged-in user is not an administrator, and the current destination path is one that only administrators have access to,
    // then redirect the user to a page without permission, preventing access to the actual page.
    next('/403')
  } else {
    next()
  }
})

export default router
